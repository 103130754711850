<script setup lang="ts">
import GoogleIcon from '@/Components/Icon/GoogleIcon.vue';
import BaseButton from '@/Components/Base/BaseButton.vue';
import { Link } from '@inertiajs/vue3';
import { ref } from 'vue';
import { Invite } from '@/generated';

const loading = ref(false);

defineProps<{
  invite?: null | Invite
}>();
</script>

<template>
  <Link
    class="w-full"
    :href="route('oauth.redirect', 'google')"
    method="post"
    :data="{
      platform: 'google',
      invite: invite?.id,
    }"
    @click="loading = true"
  >
    <BaseButton
      type="tertiary"
      label="Continue with Google"
      class="w-full"
      :loading="loading"
    >
      <template #icon>
        <GoogleIcon />
      </template>
    </BaseButton>
  </Link>
</template>
